





















import { Component, Vue, Watch } from 'vue-property-decorator';
import { CommonGetters } from '@/store/modules/common/enums';

@Component
export default class CookiesContent extends Vue {
  private cookieContent = '';

  @Watch('language')
  public loadCookiesContent(): void {
    return this.reloadContent();
  }

  public get language(): string {
    return this.$store.getters[CommonGetters.Language];
  }

  mounted(): void {
    return this.reloadContent();
  }

  private reloadContent(): void {
    this.cookieContent = require('@/assets/privacy/' +
      this.language +
      '/cookiespolicy.html');
  }
}
